import { addMinutes, format } from 'date-fns/fp'

/** Returns a Date normalized to UTC */
export const normalizeDate = (date: string | Date): Date => {
  if (date instanceof Date) {
    return date
  }

  const newDate = new Date(date)
  return /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(date)
    ? addMinutes(newDate.getTimezoneOffset())(newDate)
    : newDate
}

export const formatDisplayDate = (
  date?: string | Date | null,
  formatPattern = 'MMM dd, yyyy',
) => {
  if (!date) {
    return ''
  }

  const dateFormatter = format(formatPattern)

  return dateFormatter(normalizeDate(date))
}

/** Returns a Date and time formatted to match: Dec 13, 2023 at 12:01 AM EST */
export const formatDisplayDateTime = (date?: string, timeOnly = false) => {
  if (!date) {
    return ''
  }
  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  }).format(new Date(date))
  
  if (timeOnly)
    return time

  return `${formatDisplayDate(date, 'MM/dd/yyyy')} at ${time}`
}

export const stripTimeFromDate = (dateString: string | Date) => {
  if (!dateString) return ''

  if (dateString instanceof Date) {
    return format('yyyy-MM-dd')(dateString)
  }

  return dateString.split('T')[0]
}
